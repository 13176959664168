import React, { useState } from 'react';
import './TVLoginView.css';
import TVButton from '../../components/common/TVButton/TVButton'
import { BrowserRouter as Router, Route, Link, Switch, Redirect, NavLink } from 'react-router-dom'

const Logo = require("./../../assets/logo.png")

const TVLoginView: React.FC = () => {
    var [lang, setLang] = useState(0)
    return <div className="TVLoginView">
        <div className="TVLoginView__bar">
            <TVButton onClick={()=>
                setLang((lang+1)%2)
            } className="TVLoginView__bar__button TVLoginView__bar__button--left">Pol / Eng</TVButton>
            

            <NavLink to="/login">
                <TVButton className="TVLoginView__bar__button TVLoginView__bar__button--right">{"->|"}</TVButton>
            </NavLink>
        </div>
        <img className="TVLoginView__image" src={Logo}/>
        <div className="TVLoginView__hello">
            {["Witamy w .Coffee", "Welcome to .Coffee"][lang]}
        </div>
        <div className="TVLoginView__authorize">
            {["Pij kawę i zdobywaj bonusy!", "Drink coffee and earn bonuses!"][lang]}
        </div>
        <div className="TVLoginView__links">
            <NavLink to="/client">
            
                <TVButton style={{backgroundColor: "#ca1801"}}>{
                    ["Zaloguj się przez", "Sign in with"][lang]
                } Google</TVButton>
            </NavLink>

            <NavLink to="/client">
                <TVButton style={{backgroundColor: "#2f53f3"}}>{
                    ["Zaloguj się przez", "Sign in with"][lang]
                } Facebook</TVButton>
            </NavLink>
        </div>

        <div className="TVLoginView__divider"/>
        <div className="TVLoginView__ad">
            <a className="TVLoginView__ad--link" href="/policy">{["Zgadzasz się z warunkami, aby kontynuować", "You agree with terms and conditions to continue"][lang]}</a>
        </div>
        <div className="TVLoginView__ad">
                <a className="TVLoginView__ad--link" href="https://.com/experience">Experience</a> platform
        </div>
    </div>   
}

export default TVLoginView
