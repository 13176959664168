import React, { useState } from 'react';
import './TVClientView.css';
import TVButton from '../../components/common/TVButton/TVButton';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, NavLink } from 'react-router-dom'
import Logo from '../../assets/logo.png'
import CoffeeIcon from './CoffeeIcon'

interface CoffeeCardCupsArgs {
    count: number
    total: number
}

const CoffeeCardCups: React.FC<CoffeeCardCupsArgs> = (props) => {
    let count = props.count%(props.total)
    return (
    <div className="TVClientView__card__content__ico">
        {   
            Array.from(Array(props.total-1), (e, i) => {
                return <>
                    {(i == 5)&&<br/>}
                    <CoffeeIcon key={i} className="TVClientView__card__content__icon"  color={(i < count)?"#ffffff":"#555555"} width="30px"/>
                </>
            })
        }

        <CoffeeIcon className="TVClientView__card__content__icon" color="#f5c907" width="30px"/>
    </div>
    )
}

let getRandom: (x: number) => number = function (x: number) {
    return Math.floor(Math.random()*Math.random()*10000000%(x))
}

function generateCode (x: number): string {
    let digits = Math.floor(x)
    return ((digits<10)?"0":"")+digits
}

/* Sample data */
let code = getRandom(1000000)
let total = 10


const TVClientView: React.FC = () => {
    var [lang, setLang] = useState(0)
    var [name, setName] = useState(["Alexey", "Roman", "Michael", "Felix"][getRandom(4)])
    var [showMenu, setMenu] = useState(false)
    let [current, setCurrent] = useState(0)
    let [later, setLater] = useState(1)
    let [showPrize, switchPrize] = useState(false)

    let remainder = total - current - 1
    let status = (current < total - 1)

    return <div className="TVClientView">
            <div className="TVLoginView__bar">
                <TVButton onClick={()=>
                    setMenu(!showMenu)
                } className="TVLoginView__bar__button TVLoginView__bar__button--left">{showMenu?"✕ Close":"•••"}</TVButton>
            </div>
            {showMenu&&<div>
                <NavLink to="/">
                    <TVButton>
                        {["Wyloguj ->", "Log out ->"][lang]}
                    </TVButton>
                </NavLink>

                <TVButton onClick={()=>{
                    setLang((lang+1)%2)
                    setMenu(!showMenu)
                }} className="">
                {["Change language to English", "Zmień język na polski"][lang]}
                </TVButton>
                <br/>
                <div className="TVClientView__header">
                    {["Jak mogę się z tobą skontaktować?", "How can we name you?"][lang]}
                </div>
                <input value={name} onChange={(event)=>{setName(event.target.value)}} className="TVLoginView__input"/>
                <div className="TVLoginView__divider"/>
                <TVButton>
                        {["Usuń moje konto i dane", "Delete account and all of the  data"][lang]}
                </TVButton>
                <NavLink to="/policy">
                <TVButton>{
                    ["Warunki świadczenia usług w dobie RODO", "Terms of service in GDPR era"][lang]
                }</TVButton>
        </NavLink>
            </div>}
            {!showMenu&&<div>
            <img className="TVClientView__image" src={Logo}/>
            
            <div className="TVClientView__header--large">
                {["Witaj ponownie", "Welcome back"][lang]}, {name}</div>
            <div className="TVClientView__header">
                {["Aby otrzymać zniżkę, nazwij ten kod", "This is your order code"][lang]}
            </div>
            <div className="TVClientView__code">{generateCode(code/100%100)+" "+generateCode(code%100)}</div>
            {(later > 0)&&
                <div className="TVClientView__hint">
                    {[<>Masz {later} {(later==1)?"darmową kawę":"darmowe kawy"} zapisane na później. Zapytaj o to baristę</>,
                    <>You have {later} free {(later==1)?"coffee":"coffees"} saved for later. Ask barista for it</>][lang]}
                </div>
                
            }

            {status && <div className="TVClientView__card">
                
                <div className="TVClientView__card__content">
                    <div className="TVClientView__card__content__header"> 
                        {(remainder >= 2) && [<>
                            Jeszcze {remainder} kaw<br/> i jedna dostaniesz gratis!
                        </>, <>Drink {total - current} more coffees<br/> and get free one!</>][lang]}

                        {(remainder == 1) && [<>
                            Została ostatnia kawa,<br/> jesteśmy blisko! 
                        </>, <>Buy one last coffee<br/> and get one for free!</>][lang]}
                    </div>
                    
                    <br/>
                    <CoffeeCardCups total={total} count={current}/>
                </div>
            </div>}

            {status && <TVButton onClick={() => {
                (current < total)?setCurrent(current+1):setCurrent(current)
            }}>DEMO: Имитировать покупку</TVButton>}

            {!status && <div className="TVClientView__card TVClientView__prizecard">
                <CoffeeIcon className="TVClientView__prizecard__icon" width="40px"/>
                <div className="TVClientView__prizecard__content">
                    {["Masz kawę gratis! 🎉","This coffee is free! "][lang]}
                </div>
                <button onClick={() => {
                    setLater(later+1)
                    setCurrent(0)
                }}
                className="TVClientView__prizecard__content__button">
                    {["Wypij to następnym razem","Save your coffee for later"][lang]}
                </button>
            </div>}
            </div>}
    </div>   
}

export default TVClientView
