import React from 'react';
import './TVButton.css';

interface TVButtonProps {
    children?: any;
    className?: string;
    style?: any;
    onClick?: any;
}

const TVButton: React.FC<TVButtonProps> = (props) => {
    return <button onClick={props.onClick} style={props.style} className={"TVButton " + props.className}>
        {props.children}
    </button>   
}

export default TVButton
