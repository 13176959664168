import React from 'react'

interface CoffeeIconParams {
	width?: string | number
	height?: string | number
	color?: string
	className?: string
}
const CoffeeIcon: React.FC<CoffeeIconParams> = (props) => {
    return (
	<svg className={props.className} fill={props.color} width={props.width} height={props.width} version="1.1" id="Capa_1" x="0px" y="0px"
	 viewBox="0 0 296 296">
    	<path d="M254.5,71c0-10.307-6.815-19.052-16.175-21.966C237.429,21.865,211.785,0,180.368,0h-63.861
		c-31.32,0-56.904,21.73-57.95,48.783C48.746,51.409,41.5,60.372,41.5,71c0,11.78,8.903,21.515,20.334,22.841l15.579,194.797
		c0.333,4.158,3.804,7.362,7.975,7.362h126.1c4.171,0,7.642-3.204,7.975-7.362L235.05,93.725C246.053,92.014,254.5,82.476,254.5,71z
		 M231.5,78h-167c-3.859,0-7-3.141-7-7s3.141-7,7-7h167c3.859,0,7,3.141,7,7S235.359,78,231.5,78z M162.115,152.602
		c8.75,6.522,14.706,19.271,14.707,33.898c-0.001,20.523-11.725,37.344-26.439,38.463c0.416-17.32,2.965-26.571,5.651-36.311
		C158.661,179.133,161.361,169.333,162.115,152.602z M140.61,184.398c-2.584,9.365-5.239,19.003-6.043,35.242
		c-8.221-6.719-13.746-19.057-13.746-33.141c0.001-20.055,11.196-36.57,25.439-38.357
		C145.841,165.428,143.294,174.669,140.61,184.398z M108.394,165c-2.295,6.602-3.572,13.868-3.572,21.5
		c0,8.021,1.416,15.635,3.937,22.5H87.095l-3.519-44H108.394z M117.694,225c7.97,9.895,18.981,16.025,31.128,16.025
		c12.146,0,23.156-6.131,31.126-16.025H208.5l-4.398,55H92.773l-4.398-55H117.694z M209.78,209h-20.896
		c2.521-6.865,3.938-14.479,3.938-22.5c0-7.632-1.277-14.898-3.572-21.5h24.049L209.78,209z M180.718,149
		c-8.023-10.475-19.35-17.025-31.896-17.025s-23.873,6.551-31.895,17.025H82.809h-0.512l-0.046-0.567L77.897,94h141.08l-4.399,55
		H180.718z M74.629,48c1.566-17.859,19.743-32,41.878-32h63.861c22.135,0,40.312,14.141,41.878,32H74.629z"/>
    </svg>)
}

export default CoffeeIcon