import React from 'react';
import './TVServiceView.css';
import TVButton from '../../components/common/TVButton/TVButton'
import { BrowserRouter as Router, Route, Link, Switch, Redirect, NavLink } from 'react-router-dom'

const Logo = require("./../../assets/logo.png")

const TVServiceView: React.FC = () => {
    return <div className="TVLoginView">
        
    </div>   
}

export default TVServiceView
