import React, { useState } from 'react';
import './TVProcessView.css';
import TVButton from '../../components/common/TVButton/TVButton'
import { BrowserRouter as Router, Route, Link, Switch, Redirect, NavLink } from 'react-router-dom'

const Logo = require("./../../assets/logo.png")

interface ReceiptPositionCupsArgs {
    count: number
    name: string
    action: any
}

const ReceiptPositions: React.FC<ReceiptPositionCupsArgs> = (props) => {
    if (props.count < 1) return <></>
    return <>
        {Array.from(Array(props.count), (e, i) => {
                return <div className="TVProcess__card__element">{props.name}
                    <TVButton onClick={props.action} className="TVProcess__card__element__delete">✕</TVButton>
                </div>
        })}
    </>
}

const TVProcessView: React.FC = () => {
    let [init, setInit] = useState(true)
    let [balance, setBalance] = useState(9)
    let [paid, setPaid] = useState(0)
    let [free, setFree] = useState(0)
    let name = "Alexey"

    let makeFree = (count: number) => {
        setBalance(balance - 8 - count)
        setFree(free + count)
    }

    let makePaid = (count: number) => {
        setBalance((balance + count))
        setPaid((paid + count))
    }

    let deleteOneFree = () => {
        setBalance(balance + 9)
        setFree(free - 1)

        if ((paid + free) == 0) {
            setInit(true)
        }
    }

    let deleteOnePaid = () => {
        setBalance(balance - 1)
        setPaid(paid - 1)

        if ((paid + free) <= 1) {
            if (paid == 1) {
                setPaid(0)
                setBalance(balance - 1)
            }

            if (free == 1) {
                setFree(0)
                setBalance(balance + 9)
            }

            setInit(true)
        }
    }

    let getFree = (balance >= 9)

    return <div className="TVLoginView">
        
        <br/>
        <TVButton style={{backgroundColor:"#dd0522"}} onClick={()=>{document.location.href="/begin"}} className="TVButton--inline TVServiceView__button">✓</TVButton>
        {init &&
            <span className="TVLoginView__links">
                {
                    getFree && <TVButton onClick={()=>{
                        setInit(false)
                        makeFree(1)
                    }}
                    style={{backgroundColor:"#ffb922"}} className="TVButton--inline TVServiceView__button">F</TVButton>
                }

                <TVButton onClick={()=>{
                    setInit(false)
                    makePaid(1)
                }}
                className="TVButton--inline TVServiceView__button">1</TVButton>

                <TVButton onClick={()=>{
                    setInit(false)
                    makePaid(2)
                }}
                className="TVButton--inline TVServiceView__button">2</TVButton>

                <TVButton onClick={()=>{
                    setInit(false)
                    makePaid(3)
                }}
                className="TVButton--inline TVServiceView__button">3</TVButton>

                <TVButton onClick={()=>{
                    setInit(false)
                    makePaid(4)
                }}
                className="TVButton--inline TVServiceView__button">4</TVButton>

                {!getFree&&<TVButton onClick={()=>{
                    setInit(false)
                    makePaid(5)
                }}
                className="TVButton--inline TVServiceView__button">5</TVButton>}
        </span>}  
        
        {!init &&
        <span className="TVLoginView__links">
                {
                    getFree && <TVButton onClick={()=>makeFree(1)}
                    style={{backgroundColor:"#ffb922"}} className="TVButton--inline TVServiceView__button">+F</TVButton>
                }

                <TVButton onClick={()=>{makePaid(1)}} className="TVButton--inline TVServiceView__button">+1</TVButton>        
        </span>}
        
        <div className="TVProcess__card__divider"/>

        <div className="TVProcess__card">
            <div className="TVProcess__card__header">Client {name} total:</div>
            <div className="TVProcess__card__element--total">{paid} + {free} F</div>
            <div className="TVProcess__card____hint">-> {(balance < 9)?((9 - balance)+" cups before free"):("Free cup avaliable")}</div>
            <ReceiptPositions action={deleteOneFree} count={free} name="Free coffee"/>
            <ReceiptPositions action={deleteOnePaid} count={paid} name="Paid coffee"/>
        </div>
            
        <TVButton onClick={()=>document.location.href="/"}>Log out</TVButton>
    </div>   
}

export default TVProcessView
