import React, { useState } from 'react';
import './TVLoginView.css';
import TVButton from '../../components/common/TVButton/TVButton'
import { BrowserRouter as Router, Route, Link, Switch, Redirect, NavLink } from 'react-router-dom'

const Logo = require("./../../assets/logo.png")

const TVServiceLoginView: React.FC = () => {
    let [message, setMessage] = useState("Authorized personel login")
    let [password, updatePass] = useState("")

    let submitAction = (event: any) => {
        event.preventDefault()
        if (password == "tasty") { // For demo only, password will be salted in prod
            document.location.href="/begin"
        } else {
            setMessage("I don't recognise you :(")
        }
    }
    
    return <div className="TVLoginView">
        <div className="TVLoginView__bar">
            <NavLink to="/">
                <TVButton className="TVLoginView__bar__button TVLoginView__bar__button--left">{"<- Back"}</TVButton>
                <img className="TVClientView__logo" src={Logo}/>
            </NavLink>
        </div>
        <div className="TVLoginView__hello">
            For staff only
        </div>
        <div className="TVLoginView__authorize">
            {message}
        </div>
        <div className="TVLoginView__links">
            <form>
                <input onSubmit={submitAction} value={password} onChange={(event)=>{updatePass(event.target.value)}} className="TVLoginView__input" type="password" placeholder="Password" autoFocus={true}/>
                <TVButton onClick={submitAction}
                    style={{backgroundColor: "#1111ff"}}>{"->"}
                </TVButton>
            </form>
        </div>
    </div>   
}

export default TVServiceLoginView
