import React from 'react';
import ReactDOM from 'react-dom';
import './TenVisits.css';
import TVClientView from './views/TVClientView/TVClientView'
import TVLoginView from './views/TVLoginView/TVLoginView';
import TVServiceLoginView from './views/TVLoginView/TVServiceLoginView';

import TVServiceView from './views/TVServiceView/TVServiceView';
import TVProcessView from './views/TVProcessView/TVProcessView';
import TVServiceViewInput from './views/TVServiceView/TVServiceViewInput';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route} from 'react-router-dom'
import TVButton from './components/common/TVButton/TVButton';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      
      <Route exact path="/">
        <TVLoginView/>
      </Route>
      <Route exact path="/login">
        <TVServiceLoginView/>
      </Route>

      <Route exact path="/client">
        <TVClientView/>
      </Route>

      <Route exact path="/begin">
        <TVServiceViewInput/>
      </Route>

      <Route exact path="/staff">
        <TVServiceView/>
      </Route>

      <Route exact path="/process">
        <TVProcessView/>
      </Route>

      <Route exact path="/policy">
        <div className="TVLoginView">
          <div className="TVLoginView__bar">
              <TVButton onClick={()=>{window.history.back()}} className="TVLoginView__bar__button TVLoginView__bar__button--left">{"<-"} Back</TVButton>
          </div>            

          <h4>Terms of use and privacy policy in English is located below</h4>

          <h1> Warunki użytkowania i polityka prywatności </h1>
          <br/> Przejrzystość i informowanie opinii publicznej o tym, jak wykorzystywane są ich dane, to dwa podstawowe cele RODO.
          <br/> My (dotCoffee, <a href="dotcoffee.pl"> dotcoffee.pl </a>) zbieramy jedynie następujące dane:

          <br/> 1. Liczba Twoich wizyt na stronie dotCoffee i łączna liczba zakupionych filiżanek
          <br/> 2. Token autoryzacyjny OAuth2, dzięki czemu możesz zalogować się za pomocą Google lub Facebooka
          <br/> 3. Twoje imię i nazwisko, które można zmienić w ustawieniach konta
          <br/> 4. Twój e-mail dołączony do Twojego logowania OAuth2 przez Google lub Facebook
          
          <br/> Dane te są gromadzone podczas odwiedzania witryny internetowej, rejestracji lub logowania się lub podczas zakupu kawy
          <br/> Twoje dane zostaną wykorzystane, aby zapewnić Ci bezpłatną kawę i / lub inne bonusy i / lub wysłać Ci e-mail ze specjalnymi ofertami na inne produkty i usługi, które naszym zdaniem mogą Ci się spodobać.
          <br/> Twoje dane są bezpiecznie przechowywane na serwerach przy użyciu nowoczesnej technologii szyfrowania z ograniczoną liczbą personelu mającego do nich dostęp.
          <br/> dotCoffee pragnie przesyłać Ci informacje o naszych produktach i usługach, które naszym zdaniem mogą Ci się spodobać. Jeśli wyraziłeś zgodę na otrzymywanie informacji marketingowych, zawsze możesz zrezygnować w późniejszym terminie.
          <br/> Jeśli nie chcesz już kontaktować się z Tobą w celach marketingowych, wyślij nam e-mail na adres support@dotcoffee.pl
          <br/> dotCoffee pragnie upewnić się, że są Państwo w pełni świadomi wszystkich swoich praw do ochrony danych. Każdy użytkownik ma prawo do:
              Prawo dostępu - Masz prawo zażądać od dotCoffee kopii Twoich danych osobowych. Za tę usługę możemy naliczyć niewielką opłatę.
              Prawo do sprostowania - Masz prawo zażądać od dotCoffee poprawienia wszelkich informacji, które Twoim zdaniem są niedokładne. Masz również prawo zażądać od dotCoffee uzupełnienia informacji, które Twoim zdaniem są niekompletne.
              Prawo do usunięcia - masz prawo zażądać od dotCoffee usunięcia Twoich danych osobowych, pod pewnymi warunkami.
              Prawo do ograniczenia przetwarzania - Masz prawo zażądać od dotCoffee ograniczenia przetwarzania Twoich danych osobowych, pod pewnymi warunkami.
              Prawo do sprzeciwu wobec przetwarzania - masz prawo sprzeciwić się przetwarzaniu Twoich danych osobowych przez dotCoffee, pod pewnymi warunkami.
              Prawo do przenoszenia danych - masz prawo zażądać, aby dotCoffee przekazał zebrane przez nas dane innej organizacji lub bezpośrednio Tobie, pod pewnymi warunkami.
          <br/> Jeśli złożysz wniosek, mamy miesiąc na odpowiedź. Jeśli chcesz skorzystać z któregokolwiek z tych praw, skontaktuj się z nami pod adresem e-mail: support@dotcoffee.pl

          <h1>Terms of use and privacy policy</h1>
          <br/>Transparency and informing the public about how their data are being used are two basic goals of the GDPR.
          <br/>We (dotCoffee, <a href="dotcoffee.pl">dotcoffee.pl</a>) only collect the following data:

          <br/>1. Number of your visits to dotCoffee website and total amount of purchased coffee cups
          <br/>2. OAuth2 authorization token so you can sign in using Google or Facebook
          <br/>3. Your name, that can be modified inside your account settings
          <br/>4. Your e-mail, attached to your OAuth2 sign-in through Google or Facebook
          
          <br/> This data is being collected either when you visit the website, register or sign-in or when you buy coffee
          <br/> Your data will be used to provide you with free coffee and/or other bonuses and/or to e-mail you with special offers on other products and services we think you might like.
          <br/> Your data is stored securely inside servers using modern technology encryption with limited number of personel having access to it.
          <br/> dotCoffee would like to send you information about products and services of ours that we think you might like. If you have agreed to receive marketing, you may always opt out at a later date.
          <br/> If you no longer wish to be contacted for marketing purposes, please send us an e-mail at support@dotcoffee.pl
          <br/> dotCoffee would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
              The right to access – You have the right to request dotCoffee for copies of your personal data. We may charge you a small fee for this service.
              The right to rectification – You have the right to request that dotCoffee correct any information you believe is inaccurate. You also have the right to request dotCoffee to complete the information you believe is incomplete.
              The right to erasure – You have the right to request that dotCoffee erase your personal data, under certain conditions.
              The right to restrict processing – You have the right to request that dotCoffee restrict the processing of your personal data, under certain conditions.
              The right to object to processing – You have the right to object to dotCoffee's processing of your personal data, under certain conditions.
              The right to data portability – You have the right to request that dotCoffee transfer the data that we have collected to another organization, or directly to you, under certain conditions.
          <br/>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: support@dotcoffee.pl
        </div>
      </Route>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
