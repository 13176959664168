import React, { useState } from 'react';
import './TVServiceView.css';
import TVButton from '../../components/common/TVButton/TVButton'
import { BrowserRouter as Router, Route, Link, Switch, Redirect, NavLink } from 'react-router-dom'

const Logo = require("./../../assets/logo.png")

const TVServiceViewInput: React.FC = () => {
    let [code, updateCode] = useState("")
    let [color, updateColor] = useState("#000000")
    return <div className="TVServiceView">
        <br/>
        <div className="TVLoginView__links">
            <input style={{color: color}} value={code} onChange={(event)=>{
                let code = event.target.value.substring(0, 4)
                updateCode(code)

                updateColor("#000000")
                if (code.length == 4) {
                    if (code == "2222") {
                        document.location.href="/process"
                    } else {
                        updateColor("#ff0000")
                    }
                }
            }
            } className="TVServiceViewInput__input" pattern="[0-9]*" type="number" placeholder="Client code"/>
        </div>
        <br/>
        <span className="TVLoginView__links">
                <TVButton onClick={()=>{
                    updateCode((code + "1").substring(0, 4))
                    updateColor("#000000")
                    if (code.length == 3) {
                        if (code == "2222") {
                            document.location.href="/process"
                        } else {
                            updateColor("#ff0000")
                        }
                    }

                    if (code.length > 3) {
                        updateCode("1")
                    }
                }}
                className="TVButton--inline TVServiceView__button">1</TVButton>

                <TVButton onClick={()=>{
                    updateCode((code + "2").substring(0, 4))
                    updateColor("#000000")
                    if (code.length == 3) {
                        if (code == "222" && true) {
                            document.location.href="/process"
                        } else {
                            updateColor("#ff0000")
                        }
                    }

                    if (code.length > 3) {
                        updateCode("2")
                    }
                }}
                className="TVButton--inline TVServiceView__button">2</TVButton>

                <TVButton onClick={()=>{
                    updateCode((code + "3").substring(0, 4))
                    updateColor("#000000")
                    if (code.length == 3) {
                        if (code == "2222") {
                            document.location.href="/process"
                        } else {
                            updateColor("#ff0000")
                        }
                    }

                    if (code.length > 3) {
                        updateCode("3")
                    }
                }}
                className="TVButton--inline TVServiceView__button">3</TVButton>

                <br/>
                <TVButton onClick={()=>{
                    updateCode((code + "1").substring(0, 4))
                    updateColor("#000000")
                    if (code.length == 3) {
                        if (code == "2222") {
                            document.location.href="/process"
                        } else {
                            updateColor("#ff0000")
                        }
                    }

                    if (code.length > 3) {
                        updateCode("4")
                    }
                }}
                className="TVButton--inline TVServiceView__button">4</TVButton>

                <TVButton onClick={()=>{
                    updateCode((code + "5").substring(0, 4))
                    updateColor("#000000")
                    if (code.length == 3) {
                        if (code == "2222") {
                            document.location.href="/process"
                        } else {
                            updateColor("#ff0000")
                        }
                    }

                    if (code.length > 3) {
                        updateCode("5")
                    }
                }}
                className="TVButton--inline TVServiceView__button">5</TVButton>

                <TVButton onClick={()=>{
                    updateCode((code + "6").substring(0, 4))
                    updateColor("#000000")
                    if (code.length == 3) {
                        if (code == "2222") {
                            document.location.href="/process"
                        } else {
                            updateColor("#ff0000")
                        }
                    }

                    if (code.length > 3) {
                        updateCode("6")
                    }
                }}
                className="TVButton--inline TVServiceView__button">6</TVButton>

                <br/>
                <TVButton onClick={()=>{
                    updateCode((code + "7").substring(0, 4))
                    updateColor("#000000")
                    if (code.length == 3) {
                        if (code == "2222") {
                            document.location.href="/process"
                        } else {
                            updateColor("#ff0000")
                        }
                    }

                    if (code.length > 3) {
                        updateCode("7")
                    }
                }}
                className="TVButton--inline TVServiceView__button">7</TVButton>

                <TVButton onClick={()=>{
                    updateCode((code + "8").substring(0, 4))
                    updateColor("#000000")
                    if (code.length == 3) {
                        if (code == "2222") {
                            document.location.href="/process"
                        } else {
                            updateColor("#ff0000")
                        }
                    }

                    if (code.length > 3) {
                        updateCode("8")
                    }
                }}
                className="TVButton--inline TVServiceView__button">8</TVButton>

                <TVButton onClick={()=>{
                    updateCode((code + "9").substring(0, 4))
                    updateColor("#000000")
                    if (code.length == 3) {
                        if (code == "2222") {
                            document.location.href="/process"
                        } else {
                            updateColor("#ff0000")
                        }
                    }

                    if (code.length > 3) {
                        updateCode("9")
                    }
                }}
                className="TVButton--inline TVServiceView__button">9</TVButton>
                <br/>
                <TVButton style={{backgroundColor:"#dd0522"}} onClick={
                    ()=>{
                        updateCode("")
                        updateColor("#000000")
                    }} className="TVButton--inline TVServiceView__button">{"<-"}
                </TVButton>
        
        </span>
        <div className="TVLoginView__divider"/>
        <TVButton onClick={()=>document.location.href="/"}>Log out</TVButton>
    </div>   
}

export default TVServiceViewInput
